import { Badge } from "@mui/material"
import { Card, LineUpItem, TeamPlayer, TeamPlayerPosition } from "../../types/Saison"
import RedCard from "../../res/images/RedCard.png"
import YellowRedCard from "../../res/images/YellowRedCard.png"
import Substitute from "../../res/images/Substitute.png"
import ErrorIcon from "../../res/images/ErrorIcon.png"

const ShirtComponent = (props: {

    height?:string
    width?:string
    showPosition?:boolean
    lineUpItem?:LineUpItem
    hasPlayed?:boolean
    teamPlayer?:TeamPlayer

}) => {

    var shirtColor = "white";
    var shirtColorBorder = "black"
    var position = "";

    switch(props.teamPlayer?.position) {

        case TeamPlayerPosition.goalkeeper: 
            shirtColor = 'indianRed';
            position = 'T'
            break;
        case TeamPlayerPosition.defender: 
            shirtColor = 'steelblue';
            position = 'A'
            break;
        case TeamPlayerPosition.midfielder: 
            shirtColor = 'seagreen';
            position = 'M'
            break;
        case TeamPlayerPosition.striker: 
            shirtColor = 'dimgray';
            position = 'S'
            break;
    }

    if(props.lineUpItem?.teamOfDay) {
        shirtColor = "url(#bronze)";
    }

    if(props.lineUpItem?.playerOfDay) {
        shirtColor = "url(#gold)";
    }

    const RenderIcon = () => {

        if(props.lineUpItem?.card === Card.red) {
            return (<img src={RedCard} alt="Red Card" style={{width: "40%", verticalAlign: "bottom"}} />)
        }
        if(props.lineUpItem?.card === Card.yellowRed) {
            return (<img src={YellowRedCard} alt="Yellow/Red Card" style={{width: "40%", verticalAlign: "bottom"}} />)
        }
        if(props.lineUpItem?.notPlayed) {
            return(<img src={ErrorIcon} alt="Error" style={{width: "44%", verticalAlign: "bottom"}} />)
        }
        if(props.lineUpItem?.substitute) {
            return(<img src={Substitute} alt="Substitute" style={{width: "50%", verticalAlign: "bottom"}} />)
        }
        
        return(<div></div>)
    }

    return (
        <Badge className={props.showPosition && !props.hasPlayed ? "positionBadge" : props.showPosition && props.hasPlayed ? "positionBadgeHasPlayed" : undefined} badgeContent={props.showPosition && props.teamPlayer?.position ? position : undefined}>
            <div style={{height: props.height ? props.height : '37px'}}>
                <svg height={props.height ? props.height : '37px'} width={props.width ? props.width : '37px'} viewBox="0 0 60.674 60.674" opacity={props.teamPlayer?.active ? 1 : 0.5}>
                    <path xmlns="http://www.w3.org/2000/svg" d="M46.682,6.95c-0.675-0.675-1.813-1.147-2.768-1.147h-5.818c-0.134,0-1.318,0.04-1.516,1.438    c-0.02,0.14-0.05,0.351-0.121,0.56c-0.473,1.384-2.979,2.815-6.353,2.815s-5.88-1.432-6.353-2.815    c-0.408-1.194-1.446-1.997-2.584-1.997h-5.397c-0.627,0-0.627,0-6.953,6.322l-8.086,8.082C0.261,20.68,0.001,21.308,0,21.975    c0,0.669,0.259,1.297,0.732,1.77l7.714,7.716c0.944,0.945,2.59,0.945,3.536,0l2.289-2.29v23.2c0,1.379,1.122,2.5,2.5,2.5h26.854    c1.378,0,2.5-1.121,2.5-2.5V28.892l2.568,2.568c0.944,0.945,2.591,0.945,3.535,0l7.714-7.716c0.472-0.472,0.732-1.1,0.732-1.768    s-0.26-1.296-0.732-1.767L46.682,6.95z M10.214,28.985l-7.007-7.008c0.571-0.571,1.199-1.199,1.857-1.857l7.103,6.912    L10.214,28.985z M44.924,24.252c-0.894,0-1.799,0.692-1.799,2.018v25.601H17.271V26.548c0-1.325-0.905-2.017-1.799-2.017    c-0.367,0-0.919,0.115-1.469,0.664l-1.128,1.129l-7.103-6.911C9.87,15.316,14.91,10.28,16.39,8.805h4.536    c0.993,2.835,4.76,4.813,9.18,4.813s8.188-1.978,9.18-4.813h4.628c0.162,0.002,0.531,0.155,0.647,0.268l10.473,10.472    l-7.103,6.911l-1.538-1.54C45.844,24.367,45.291,24.252,44.924,24.252z M50.46,28.985l-1.823-1.823l7.104-6.912l1.727,1.727 L50.46,28.985z"style={{fill: shirtColorBorder}} />
                    <path d="M 5.574 19.473 L 16.274 8.772 L 21.126 8.856 C 20.932 10.885 26.658 13.784 29.899 13.54 C 33.511 13.697 37.116 12.506 39.32 8.787 L 44.392 8.704 L 55.222 19.541 C 55.222 19.541 47.429 26.608 47.927 26.654 C 48.425 26.7 43.73 22.757 43.858 25.238 C 42.776 21.275 43.401 52.873 43.327 52.03 L 17.219 51.878 C 17.219 51.878 17.423 26.131 16.18 25.483 C 16.796 23.639 13.054 25.766 12.816 26.487 L 5.574 19.473 Z" style={{fill: shirtColor}} transform="matrix(1, 0, 0, 1, 3.552713678800501e-15, 7.105427357601002e-15)"/>

                    {
                    // UNTERSCHIEDLICHE SHIRT-MUSTER

                    /* {props.teamPlayerPosition == TeamPlayerPosition.midfielder && (

                        <g transform="matrix(1, 0, 0, 1, 0, 8.881784197001252e-16)">
                            <path d="M 11.831 12.777 L 15.627 9.508 L 15.838 25.114 L 12.148 26.908 L 11.831 12.777 Z"/>
                            <path d="M 19.634 7.979 L 24.591 10.404 L 24.485 52.476 L 19.74 52.161 L 19.634 7.979 Z"/>
                            <path d="M 28.229 12.092 L 33.186 12.091 L 33.502 52.688 L 28.546 52.583 L 28.229 12.092 Z"/>
                            <path d="M 36.822 10.616 L 40.935 6.609 L 41.568 53.005 L 36.928 53.638 L 36.822 10.616 Z"/>
                            <path d="M 43.993 7.557 L 48 10.299 L 48.527 26.854 L 44.52 26.644 L 43.993 7.557 Z"/>
                        </g>
                    )}

                    {props.teamPlayerPosition == TeamPlayerPosition.striker && (
                        <path d="M 9.959 12.791 L 13.611 9.615 L 16.944 7.71 L 44.409 44.383 L 44.568 48.51 L 43.933 52.162 L 39.487 53.273 L 9.959 12.791 Z" transform="matrix(1, 0, 0, 1, 3.552713678800501e-15, 0)"/>
                    )}
                    
                    {props.teamPlayerPosition == TeamPlayerPosition.defender && (
                        <path d="M 25.041 11.203 L 30.121 12.314 L 34.883 11.044 L 35.36 52.796 L 25.675 52.797 L 25.041 11.203 Z" transform="matrix(1, 0, 0, 1, 3.552713678800501e-15, 0)"/>

                    )} */}

                    {props.teamPlayer?.number && (
                        <text style={{fontSize: "21px", fontWeight: 700, whiteSpace: "pre", fill: props.lineUpItem?.teamOfDay || props.lineUpItem?.playerOfDay ? "#000000" : "#FFFFFF"}} x={props.teamPlayer?.number > 9 ? "18px" : "24px"} y="35" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 5.684341886080802e-14, 2.842170943040401e-14)">{props.teamPlayer?.number}</text>
                    )}   
                    
                    <radialGradient gradientUnits="userSpaceOnUse" cx="36.185" cy="18.255" r="24.824" id="gold" gradientTransform="matrix(0.763457, 1.310083, -0.864, 0.503498, 7.41374, -43.886378)">
                        <stop offset="0" style={{stopColor: "rgb(249, 232, 192)"}} />
                        <stop offset="1" style={{stopColor: " rgb(232, 167, 58)"}} />
                    </radialGradient>

                    <radialGradient gradientUnits="userSpaceOnUse" cx="36.185" cy="18.255" r="24.824" id="silver" gradientTransform="matrix(0.763457, 1.310083, -0.864, 0.503498, 7.41374, -43.886378)">
                        <stop offset="0" style={{stopColor: "rgb(233, 232, 231)"}} />
                        <stop offset="1" style={{stopColor: " rgb(174, 173, 172)"}} />
                    </radialGradient>
        
                    <radialGradient gradientUnits="userSpaceOnUse" cx="36.185" cy="18.255" r="24.824" id="bronze" gradientTransform="matrix(0.763457, 1.310083, -0.864, 0.503498, 7.41374, -43.886378)">
                        <stop offset="0" style={{stopColor: "rgb(249, 232, 192)"}} />
                        <stop offset="1" style={{stopColor: " rgb(219, 108, 43)"}} />
                    </radialGradient>
                </svg>
            </div>
            <div style={{position: 'absolute', bottom: 0}} >
                <RenderIcon />
            </div>
        </Badge>
    )

}

export default ShirtComponent