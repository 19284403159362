import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { Saison, SaisonState, UserSaisonAlloc } from "../../types/Saison";
import React from "react";
import HttpService from "../../services/HttpService";
import DialogProgressComponent from "../common/DialogProgressComponent";
// import { Editor } from "@tinymce/tinymce-react";

export interface Props {
    open: boolean;
    saison: Saison;
    userSaisonAllocs: UserSaisonAlloc[];
    onOk: () => void;
    onAbort: () => void;
}

function EditSettingsDialogComponent(props:Props) {

    const { open, onOk, onAbort } = props;
    const [ loading, setLoading ] = React.useState(false);
    const [ winnerUserId, setWinnerUserId ] = React.useState("");
    const [ state, setState ] = React.useState(SaisonState.Active);
    const [ name, setName ] = React.useState("");
    const [ tippspiel, setTippspiel ] = React.useState(false); 
    const [ validateLineUp, setValidateLineUp ] = React.useState(false);

    React.useEffect(() => {
        if(props.saison) {
            setState(props.saison.state);
            setTippspiel(props.saison.tippspiel);
            setValidateLineUp(props.saison.validateLineUp)
            setWinnerUserId(props.saison.winnerUserId);
            setName(props.saison.name)
        }
    },[props.saison])

    const handleAbort = () => {
        onAbort();
    }

    const handleOk = () => {
        setLoading(true);
        HttpService.post("/SaisonAPI/Saison", {
            id: props.saison.id,
            state: state,
            winnerUserId: winnerUserId,
            tippspiel: tippspiel,
            validateLineUp: validateLineUp,
            name: name
          }).then(res => {
            if(res.status === 200) {
                onOk();
                setLoading(false);
            } 
          })
    }

    return (
        <Dialog fullWidth={true} open={open}>
            <DialogTitle>{name}</DialogTitle>
            {loading && (
                <DialogProgressComponent delay={1000} />
            )}
            <DialogContent dividers>

                <TextField sx={{marginTop: 3}} required={true} size="small" fullWidth id="outlined-basic" label="NAME" variant="outlined" value={name} onChange={e => setName(e.target.value)} />

                <FormControl sx={{marginTop: 3}} fullWidth>
                    <InputLabel>GEWINNER</InputLabel>
                    <Select fullWidth={true} value={winnerUserId} size="small" label="MANAGER" onChange={e => setWinnerUserId(e.target.value)}>
                        <MenuItem value = "">
                            <em>None</em>
                        </MenuItem>
                        {props.userSaisonAllocs?.sort((a,b) => a.playerName.localeCompare(b.playerName)).map((UserSaisonAlloc) => (
                            <MenuItem key={UserSaisonAlloc.id} value={UserSaisonAlloc.userId}>{UserSaisonAlloc.playerName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop: 3}} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">STATUS</InputLabel>
                    <Select fullWidth={true} value={state} size="small" label="POSITION" onChange={e => setState(Number(e.target.value))}>
                        <MenuItem value={SaisonState.Active}>AKTIV</MenuItem>
                        <MenuItem value={SaisonState.Finished}>BEENDET</MenuItem>
                        <MenuItem value={SaisonState.InActive}>INAKTIV</MenuItem>
                    </Select>
                </FormControl>

                <FormControlLabel sx={{marginTop: 1}} control={<Checkbox checked={tippspiel} onChange={e => setTippspiel(e.target.checked)}></Checkbox>} label="TIPPSPIEL AKTIV?" />

                <FormControlLabel sx={{marginTop: 1}} control={<Checkbox checked={validateLineUp} onChange={e => setValidateLineUp(e.target.checked)}></Checkbox>} label="AUFSTELLUNGEN PRÜFEN?" />

                {/* <Editor 
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                /> */}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleAbort} disabled={loading}>ABBRECHEN</Button>
                <Button onClick={handleOk} disabled={loading}>ÜBERNEHMEN</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditSettingsDialogComponent