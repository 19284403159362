import { Box, Grid2 } from "@mui/material"
import Spielfeld from "../../res/images/Spielfeld.png"
import { TeamPlayer, TeamPlayerPosition } from "../../types/Saison"
import ShirtComponent from "./ShirtComponent"

const FieldComponent = (props: {teamPlayers:TeamPlayer[], height?:string}) => {

    

    return (
        <Grid2 container sx={{
            backgroundImage: `url(${Spielfeld})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            // backgroundColor: 'rgba(255,255,255,0.4)',
            // backgroundBlendMode: 'lighten',
            backgroundPosition: 'center',
            height: props.height,
            minHeight: '150px'}}>
            <Grid2 container size={12}>
               <Grid2 container size={12} sx={{pt:2}} justifyContent={'center'}>
                    {props.teamPlayers.filter(s => s.position === TeamPlayerPosition.striker).map((TeamPlayer) => (
                        <Grid2 sx={{pb:1}} size={4} textAlign="center" key={TeamPlayer.id}>
                            <ShirtComponent teamPlayer={TeamPlayer} />
                            <Box className="playerInField">{getName(TeamPlayer)}</Box>
                        </Grid2>
                    ))}
                </Grid2> 
            </Grid2>
            <Grid2 container size={12}>
                <Grid2 container size={12} justifyContent={'center'}>
                    {props.teamPlayers.filter(s => s.position === TeamPlayerPosition.midfielder).map((TeamPlayer) => (
                        <Grid2 sx={{pb:1}} size={4} textAlign="center" key={TeamPlayer.id}>
                            <ShirtComponent teamPlayer={TeamPlayer}/>
                            <Box className="playerInField">{getName(TeamPlayer)}</Box>
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
            <Grid2 container size={12}>
                <Grid2 container size={12} justifyContent={'center'}>
                    {props.teamPlayers.filter(s => s.position === TeamPlayerPosition.defender).map((TeamPlayer) => (
                        <Grid2 sx={{pb:1}} size={4} textAlign="center" key={TeamPlayer.id}>
                            <ShirtComponent teamPlayer={TeamPlayer}/>
                            <Box className="playerInField">{getName(TeamPlayer)}</Box>
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
            <Grid2 container size={12}>
                <Grid2 container size={12} justifyContent={'center'}>
                    {props.teamPlayers.filter(s => s.position === TeamPlayerPosition.goalkeeper).map((TeamPlayer) => (
                        <Grid2 sx={{pb:1}} size={4} textAlign="center" key={TeamPlayer.id}>
                            <ShirtComponent teamPlayer={TeamPlayer}/>
                            <Box className="playerInField">{getName(TeamPlayer)}</Box>
                        </Grid2>
                    ))}
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

const getName = (TeamPlayer:TeamPlayer) => {

    var result = TeamPlayer.name;

    if(TeamPlayer.info && TeamPlayer.info.startsWith("SUM_TEAM_OF_DAY:")) {
        result = result + " (" + TeamPlayer.info.split(":")[1] + ")"
    }

    return result;
}

export default FieldComponent