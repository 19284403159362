import axios from 'axios'

export var baseUrl = 'https://api.manager-spiel.de'

if(!process.env.NODE_ENV 
    || process.env.NODE_ENV === 'development' 
    || window.location.origin.toLowerCase() === "http://localhost:8082"
    || window.location.origin.toLowerCase() === "https://localhost:8082") {
    baseUrl = 'https://localhost:44336'
}

const axiosInstance = axios.create({
    baseURL: baseUrl + '/API',
    headers: {
      'Content-Type': 'application/json'
    },
  });

axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    response => {
        return response
    },
 
    function (error) {

        if (error.code === 'ERR_NETWORK') {
            if( error.config.url.includes("AccountAPI/UserInformations") ||
            error.config.url.includes("AccountAPI/Heartbeat") ||
            error.config.url.includes("MatchDayAPI/NewMatchDayMatchEventsAvailable")
            ) {
                return Promise.reject(error)
            }
            window.location = '/'
        }

        if (error.response.status === 403) {
            window.location = '/403'
        }

        if (error.response.status === 401) {
            if( error.config.url.includes("AccountAPI/UserInformations") ||
                error.config.url.includes("AccountAPI/Login") ||
                error.config.url.includes("AccountAPI/ResetPassword") ||
                error.config.url.includes("AccountAPI/Heartbeat") ||
                error.config.url.includes("MatchDayAPI/NewMatchDayMatchEventsAvailable")
                )
                return error

            window.location = '/'
        }
        return error.response;
    }
)

var exportDefault = {
    baseUrl: baseUrl,
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
    patch: axiosInstance.patch,
};

export default exportDefault