import { Add, CheckCircle, Lock, LockOpen } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Fab, Fade, Grid2, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import Loader from "../components/common/Loader";
import HttpService from "../services/HttpService";
import { MatchDaysModel, MatchDayState } from "../types/MatchDay";
import { AllocRole } from "../types/Saison";
import ConformationDialog from "../components/common/ConfirmationDialog";
import MatchDayMatches from "../components/matchDay/MatchDayMatchesComponent";
import { MatchDayMatchVariant } from "../components/matchDay/MatchDayMatchComponent";

const MatchDaysPage = () => {

    let { id } = useParams();

    const [matchDaysModel, setMatchDaysModel] = React.useState<MatchDaysModel>();
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

    const ctx = React.useContext(AppContext)
    const navigate = useNavigate();

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/MatchDayAPI/MatchDays', {
            params: { saisonId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }

                ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                ctx?.setSaison(res.data.saison)

                setMatchDaysModel(res.data);
            }); 

    }, [id])

    // const scrollToMatchDay = () => {
    //     if(matchDaysModel) {

    //         var matchDayMatch = matchDaysModel.matchDayMatches.filter(s => s.matchDate > matchDaysModel.serverDateTime).sort((a,b) => a.matchDate < b.matchDate ? -1 : 1)
    //         console.log(matchDayMatch)

    //         document.querySelector(`[id='anchor${matchDayMatch[0].matchDayId}']`)?.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'center',
    //             inline: 'nearest'
    //         })
    //     }
    // }

    const handleMatchDayClick = (matchDayId:string) => {
        navigate('/matchDay/' + matchDayId)
    }

    const handleCreateNewMatchDay = () => {
        if(!showConfirmDialog) {
            setShowConfirmDialog(true);
            return;
        }
        setShowConfirmDialog(false);
        HttpService.get('/MatchDayAPI/CreateNewMatchDay', {
            params: { saisonId: id}
        }).then(res => {
            if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log(res.data);
            }
            res.data.isNew = true;
            navigate('/matchDay/' + res.data.id)
        })
    }

    return(
        <div>
            {matchDaysModel && (
                <Fade in>
                    <Box>
                        {/* <Card>
                            <CardHeader title="SPIELTAGE" />
                            <CardContent>
                                <TableContainer>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>NUMMER</TableCell>
                                                <TableCell>VON</TableCell>
                                                <TableCell>BIS</TableCell>
                                                <TableCell>STATUS</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {matchDaysModel.matchDays.map((MatchDay) => (
                                                <TableRow 
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
                                                    key={MatchDay.id}
                                                    onClick={e => handleMatchDayClick(MatchDay.id)}
                                                    >
                                                    <TableCell>
                                                        {MatchDay.number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {matchDaysModel.matchDayMatches.find(s => s.matchDayId === MatchDay.id) && (
                                                            moment(matchDaysModel.matchDayMatches.filter(s => s.matchDayId === MatchDay.id).sort((a,b) => a.matchDate.localeCompare(b.matchDate))[0].matchDate).format('DD.MM.YYYY')
                                                        )}     
                                                    </TableCell>
                                                    <TableCell>
                                                        {matchDaysModel.matchDayMatches.find(s => s.matchDayId === MatchDay.id) && (
                                                            moment(matchDaysModel.matchDayMatches.filter(s => s.matchDayId === MatchDay.id).sort((a,b) => b.matchDate.localeCompare(a.matchDate))[0].matchDate).format('DD.MM.YYYY')
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {MatchDay.state === MatchDayState.OPENED && (
                                                            <LockOpen />
                                                        )}
                                                        {MatchDay.state === MatchDayState.CLOSED && (
                                                            <Lock />
                                                        )}
                                                        {MatchDay.state === MatchDayState.FINISHED && (
                                                            <CheckCircle />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <ConformationDialog
                                    onCancel={() => setShowConfirmDialog(false)}
                                    onNo={() => setShowConfirmDialog(false)}
                                    open={showConfirmDialog}
                                    title="NEUER SPIELTAG"
                                    onYes={handleCreateNewMatchDay}
                                    >
                                        Neuen Spieltag erstellen?
                                    </ConformationDialog>
                                </TableContainer>
                            </CardContent>
                        </Card> */}
                        {/* <Grid container spacing={2}>
                        {matchDaysModel.matchDays.map((MatchDay) => (
                            <Grid item xs={12} sm={6} md={4} xl={3} key={MatchDay.id}>
                                <Card>
                                    <CardHeader title={'SPIELTAG ' + MatchDay.number}/>
                                    <CardContent>
                                        <MatchDayMatches
                                            matchDayMatches={matchDaysModel.matchDayMatches.filter(s => s.matchDayId === MatchDay.id)}
                                            matchDayTeams={matchDaysModel.matchDayTeams.filter(s => s.matchDayId === MatchDay.id)}
                                            teams={matchDaysModel.teams}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                        </Grid> */}
                        <Grid2 container spacing={2}>
                            {matchDaysModel.matchDays.sort((a,b) => a.number > b.number ? -1 : 1).map((MatchDay) => (
                                <Grid2 size={{xs: 12, md: 6}} key={MatchDay.id}>
                                    <Card key={MatchDay.id} id={'anchor' + MatchDay.id} onClick={e => handleMatchDayClick(MatchDay.id)} sx={{cursor: 'pointer'}}>
                                        <CardHeader 
                                            //title={'SPIELTAG ' + MatchDay.number + "(" + moment(matchDaysModel.matchDayMatches.filter(s => s.matchDayId === MatchDay.id).sort((a,b) => a.matchDate < b.matchDate ? -1 : 1)[0].matchDate).format("DD.MM.") + " - " + ")"} 
                                            title={
                                                <Grid2 container>
                                                    <Grid2 size={'auto'}>
                                                        {MatchDay.state === MatchDayState.OPENED && (
                                                            <LockOpen />
                                                        )}
                                                        {MatchDay.state === MatchDayState.CLOSED && (
                                                            <Lock />
                                                        )}
                                                        {MatchDay.state === MatchDayState.FINISHED && (
                                                            <CheckCircle />
                                                        )}
                                                        &nbsp;
                                                    </Grid2>
                                                    <Grid2 size={'auto'}>
                                                        <Typography variant="h6" lineHeight={'1.2'}>{MatchDay.number}. SPIELTAG&nbsp;</Typography>
                                                    </Grid2>
                                                    <Grid2 size={'auto'}>
                                                        <Typography>({moment(matchDaysModel.matchDayMatches.filter(s => s.matchDayId === MatchDay.id).sort((a,b) => a.matchDate < b.matchDate ? -1 : 1)[0].matchDate).format("DD.MM.")}&nbsp;-&nbsp;</Typography>
                                                    </Grid2>
                                                    <Grid2 size={'auto'}>
                                                        <Typography>{moment(matchDaysModel.matchDayMatches.filter(s => s.matchDayId === MatchDay.id).sort((a,b) => a.matchDate > b.matchDate ? -1 : 1)[0].matchDate).format("DD.MM.")})</Typography>
                                                    </Grid2>
                                                </Grid2>
                                            }
                                        />
                                        <CardContent>
                                            <Grid2 container>
                                                <Grid2 size={{xs: 12}}>
                                                    <MatchDayMatches 
                                                        matchDayMatches={matchDaysModel.matchDayMatches.filter(s => s.matchDayId === MatchDay.id)} 
                                                        matchDayTeams={matchDaysModel.matchDayTeams.filter(s => s.matchDayId === MatchDay.id)} 
                                                        teams={matchDaysModel.teams} 
                                                        serverDateTime={matchDaysModel.serverDateTime}
                                                        variant={MatchDayMatchVariant.small}
                                                    />
                                                </Grid2>
                                            </Grid2>
                                        </CardContent>
                                    </Card>
                                </Grid2>
                            ))}
                        </Grid2>
                        
                        {matchDaysModel.userSaisonAlloc.allocRole === AllocRole.owner && ctx?.saison?.state === 0 && (
                            <Box>
                                <Fab color="primary" aria-label="add" size="small" onClick={handleCreateNewMatchDay} sx={{position: 'fixed', bottom: {xs: '66px',  md: '16px'}, right: '16px'}}>
                                    <Add />
                                </Fab>
                            </Box>
                        )} 
                    </Box>
                </Fade>
            )}
            {!matchDaysModel && (
                <Loader delay={1000}>Spieltage werden geladen...</Loader>
            )}
            <ConformationDialog
                onCancel={() => setShowConfirmDialog(false)}
                onNo={() => setShowConfirmDialog(false)}
                open={showConfirmDialog}
                title="NEUER SPIELTAG"
                onYes={handleCreateNewMatchDay}
                >
                    Neuen Spieltag erstellen?
            </ConformationDialog>
        </div>
    )
}

export default MatchDaysPage