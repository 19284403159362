import { MatchDay, MatchDayMatch, MatchDayTeam } from "./MatchDay";
import { Tipper, Tipp } from "./Tipp";

export type Saison = {
    id: string;
    name: string;
    tippspiel:boolean;
    state:SaisonState;
    memo:string;
    winnerUserId:string;
    validateLineUp:boolean;
}

export type Manager = {
    points: number;
    goals: number;
    prePoints: number;
    preGoals: number;
    rang: number;
    preRang: number;
    matchDays: number;
    userSaisonAlloc:UserSaisonAlloc
}

export type UserSaisonAlloc = {
    id: string;
    userId: string;
    playerName: string;
    isOnline: boolean;
    allocRole:AllocRole;
    allocState:AllocState;
    saisonId:string;
    avatar:string;
    avatarThumbBase64:string
}


export type LineUp = {
    id:string;
    assists:number;
    assistsPlacePoints:number;
    goals:number;
    goalsPlacePoints:number;
    goalsConceded:number;
    goalsConcededPlacePoints:number;
    matchDayId:string;
    place:number;
    placePoints:number;
    playerOfDayPoints:number;
    teamOfDayPoints:number;
    teamPlayerPointPlacePoints:number;
    teamPlayerPoints:number;
    userSaisonAllocId:string;
}

export type LineUpItem = {
    id:string;
    lineUpId:string;
    teamPlayerId:string;
    goals:number;
    assists:number;
    grade:number;
    teamOfDay:boolean;
    playerOfDay:boolean;
    playerOfMatch:boolean;
    notPlayed:boolean;
    substitute:boolean;
    card:Card;
    points:number;
    changeable?:boolean;
}

export type LineUpItemChangeLog = {
    id:string
    dateTime:string
    newTeamPlayerName:string
    teamPlayerPosition:TeamPlayerPosition
    newTeamPlayerNumber:number
    oldTeamPlayerName:string
    oldTeamPlayerPosition:TeamPlayerPosition
    oldTeamPlayerNumber:number
    oldTeamPlayerTeamName:string
    oldTeamPlayerTeamImageLogoPath:string
    oldTeamPlayerTeamShortName:string
    newTeamPlayerTeamName:string
    newTeamPlayerTeamImageLogoPath:string
    newTeamPlayerTeamShortName:string
    lineUpId:string
    matchDayId:string
    userName:string
    newTeamPlayerId:string
    oldteamPlayerId:string
}

export type Team = {
    id:string;
    name:string;
    shortName:string;
    imageLogoPath:string;
    teamSuggestId:string;
    saisonId:string
    logoBase64:string
}

export type TeamPlayer = {
    id:string;
    userSaisonAllocId:string;
    name:string;
    teamId:string;
    position:TeamPlayerPosition;
    number:number;
    price:number;
    kickerPrice:number;
    active:boolean;
    newTeamPlayerId:string;
    info:string;
    note:string;
    noteDate:string;
    sequence?:number;
    newTeamPlayerFrom?:string;
}

export type SaisonEvent = {
    id:string;
    title:string;
    memo:string;
    startDate:string;
    endDate:string;
    location:string
    priority:EventPriority
}

export type SaisonModel = {
    saison:Saison;
    managers:Manager[];
    lastMatchDay?:MatchDay;
    currentMatchDay?:MatchDay;
    currentMatchDayMatches:MatchDayMatch[];
    currentMatchDayTeams:MatchDayTeam[];
    lastLineUps:LineUp[];
    userSaisonAlloc:UserSaisonAlloc;
    tipps:Tipp[];
    tippers:Tipper[];
    lastMatchDayMatches:MatchDayMatch[];
    lastMatchDayTeams:MatchDayTeam[];
    userSaisonAllocs:UserSaisonAlloc[];
    teams: Team[];
    serverDateTime:string;
}

export type TeamLeagueTableItem = {
    saisonId:string,
    name:string,
    teamId:string,
    points:number,
    goals:number,
    goalsConceded:number,
    won:number,
    lost:number,
    draw:number,
    spieltage:number,
    rang:number
}

export type TeamLeagueModel = {
    teamLeagues:TeamLeagueTableItem[],
    teams:Team[]
}

export type TeamPlayersModel = {
    saison:Saison,
    teams: Team[],
    teamPlayers: TeamPlayer[],
    userSaisonAllocs:UserSaisonAlloc[],
    userSaisonAlloc:UserSaisonAlloc
}

export type AdministrationModel = {
    saison:Saison,
    userSaisonAllocs:UserSaisonAlloc[],
    userSaisonAlloc:UserSaisonAlloc,
    teams:Team[]
    events:SaisonEvent[]
}

export type ManagerStatisticsModel = {
    userSaisonAlloc:UserSaisonAlloc,
    lineUpItems:LineUpItem[],
    lineUps:LineUp[],
    teamPlayers:TeamPlayer[]
}

export type TeamPlayerStatistics = {
    teamPlayerId:string,
    points:number
}

export type V_TeamPlayerStatistics = {
    teamPlayerId:string,
    name:string,
    sumSpieltage:number,
    sumGoals:number,
    sumAssists:number,
    avgGrade:number,
    sumTeamOfDay:number,
    sumPlayerOfDay:number,
    sumPlayerOfMatch:number,
    sumNotPlayed:number,
    sumSubstitute:number,
    price:number,
    kickerPrice:number,
    saisonId:string,
    imageLogoPath:string,
    teamName:string,
    teamId:string,
    shortName:string,
    userName:string
    position:number
    number:number
    sumPoints:number
    sumRedCards:number
    sumYellowRedCards:number
}

export type TeamPlayerStatisticsModel = {
    teamPlayerStatistics:V_TeamPlayerStatistics,
    lineUpItem:LineUpItem
}

export type TeamStatisticsModel = {
    userSaisonAlloc:UserSaisonAlloc
    saison:Saison
    team:Team
    teamPlayerStatistics:V_TeamPlayerStatistics[]
    last10Matches:MatchDayMatchStatistic[]
    nextMatches:MatchDayMatchStatistic[]
    topPlayersGoal:V_TeamPlayerStatistics[]
    topPlayersAssist:V_TeamPlayerStatistics[]
    topPlayersGrade:V_TeamPlayerStatistics[]
    teamPlayerSuggests:TeamPlayerSuggest[]
    serverDateTime:string
}

export type MatchDayMatchStatistic = {
    teamId:string
    won:boolean
    draw:boolean
    lost:boolean
    matchDayMatch:MatchDayMatch
    team1:MatchDayMatchTeamStatistic
    team2:MatchDayMatchTeamStatistic
}

export type MatchDayMatchTeamStatistic = {
    matchDayTeam:MatchDayTeam
    team:Team
}

export type TeamPlayerSuggest = {
    id:string
    name:string
    position:TeamPlayerPosition
    kickerPrice:number
    teamId:string
    number:number
}

export type TeamPlayerSuggestsModel = {
    teamPlayerSuggests:TeamPlayerSuggest[]
    userSaisonAllocs:UserSaisonAlloc[]
    saison:Saison
    userSaisonAlloc:UserSaisonAlloc
    teams:Team[]
}

export type ConfirmImportTeamPlayerSuggestsModel = {
    fileName:string
    saisonId:string
    count:number
    countUpdate:number
    teamMappings:TeamMapping[]
    teams:Team[]
}

export type TeamMapping = {
    kickerTeam:string
    teamId:string
}

export enum Card {
    none = 0,
    yellow = 1,
    yellowRed = 2,
    red = 3
}

export enum TeamPlayerPosition {
    goalkeeper = 1,
    defender = 2,
    midfielder = 3,
    striker = 4
}

export enum AllocRole {
    owner = 1,
    member = 2,
    guest = 3
}

export enum AllocState {
    Invited = 1,
    Active = 2
}

export enum SaisonState
{
  Active = 0,
  InActive = 1,
  Finished = 2
}

export enum EventPriority 
{
  Normal = 0,
  Important = 1
}

export const calculatePoints = (LineUpItem:LineUpItem) => {

    LineUpItem.points = 0

        if(LineUpItem.grade === 1)
            LineUpItem.points += 5
        if(LineUpItem.grade === 1.5)
            LineUpItem.points += 4
        if(LineUpItem.grade === 2)
            LineUpItem.points += 3
        if(LineUpItem.grade === 2.5)
            LineUpItem.points += 2
        if(LineUpItem.grade === 3)
            LineUpItem.points += 1
        if(LineUpItem.grade === 3.5)
            LineUpItem.points += 0
        if(LineUpItem.grade === 4)
            LineUpItem.points += -1
        if(LineUpItem.grade === 4.5)
            LineUpItem.points += -2
        if(LineUpItem.grade === 5)
            LineUpItem.points += -3
        if(LineUpItem.grade === 5.5)
            LineUpItem.points += -4
        if(LineUpItem.grade === 6)
            LineUpItem.points += -5

        if(LineUpItem.playerOfMatch)
            LineUpItem.points += 1

        if(LineUpItem.card === Card.yellowRed)
            LineUpItem.points += -5
        if(LineUpItem.card === Card.red)
            LineUpItem.points += -7

        if(LineUpItem.notPlayed)
            LineUpItem.points += -8
}