import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { V_TeamPlayerStatistics } from "../../types/Saison"
import ShirtComponent from "../common/ShirtComponent"
import HttpService from "../../services/HttpService"

export enum RankingType {
    GRADE = 1,
    GOALS = 2,
    ASSISTS = 3,
    POINTS = 4,
    SCORER = 5
}

const TopTeamPlayerRanking = ((props: {
    TeamPlayerStatistics:V_TeamPlayerStatistics[],
    Type:RankingType
    Cnt?:number
}) => {

    let playerCnt = 5

    if(props.Cnt)
        playerCnt = props.Cnt

    let sortedList = new Array<V_TeamPlayerStatistics>();
    let list = new Array<V_TeamPlayerStatistics>();

    list = props.TeamPlayerStatistics

    sortedList = list.sort((a,b:V_TeamPlayerStatistics) => {
        switch(props.Type) {
            case RankingType.GOALS: return a.sumGoals < b.sumGoals ? 1 : a.sumGoals > b.sumGoals ? -1 : a.name > b.name ? 1 : -1
            case RankingType.ASSISTS: return a.sumAssists < b.sumAssists ? 1 : a.sumAssists > b.sumAssists ? -1 : a.name > b.name ? 1 : -1
            case RankingType.GRADE: return a.avgGrade > b.avgGrade ? 1 : a.avgGrade < b.avgGrade ? -1 : a.name > b.name ? 1 : -1
            case RankingType.POINTS: return a.sumPoints < b.sumPoints ? 1 : a.sumPoints > b.sumPoints ? -1 : a.name > b.name ? 1 : -1
            case RankingType.SCORER: return (a.sumGoals + a.sumAssists) < (b.sumGoals + b.sumAssists) ? 1 : (a.sumGoals + a.sumAssists) > (b.sumGoals + b.sumAssists) ? - 1 : a.name > b.name ? 1 : -1
        }
        return -1
    })

    return (
        <TableContainer sx={{overflowY: 'hidden', paddingBottom: 1}}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>NAME</TableCell>
                        <TableCell sx={{display: {xs: 'none', xl: 'table-cell'}}} align="center">TEAM</TableCell>
                        <TableCell sx={{display: {xs: 'none', xl: 'table-cell'}}}>MANAGER</TableCell>
                        <TableCell align="right">SP</TableCell>
                        <TableCell align="right">
                            {props.Type === RankingType.GOALS && (
                                <Box>TORE</Box>
                            )}
                            {(props.Type === RankingType.ASSISTS || props.Type === RankingType.SCORER) && (
                                <Box>VOR.</Box>
                            )}
                            {props.Type === RankingType.GRADE && (
                                <Box>NOTE</Box>
                            )}
                            {props.Type === RankingType.POINTS && (
                                <Box>PKT.</Box>
                            )}
                        </TableCell>
                        {props.Type === RankingType.SCORER && (
                            <TableCell align="right">
                                <Box>TORE</Box>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedList.slice(0, playerCnt).map((V_TeamPlayerStatistics, index) => {
                        return (
                            <TableRow key={V_TeamPlayerStatistics.teamPlayerId}>
                                <TableCell padding={'none'} sx={{paddingLeft: '0px', paddingRight: '3px', width: '40px'}}>
                                    <ShirtComponent showPosition={true} hasPlayed={true} teamPlayer={{
                                        active: true,
                                        id: V_TeamPlayerStatistics.teamPlayerId,
                                        info: "",
                                        kickerPrice: V_TeamPlayerStatistics.kickerPrice,
                                        name: V_TeamPlayerStatistics.name,
                                        newTeamPlayerId: "",
                                        note: "",
                                        noteDate: "",
                                        number: V_TeamPlayerStatistics.number,
                                        position: V_TeamPlayerStatistics.position,
                                        price: V_TeamPlayerStatistics.price,
                                        teamId: V_TeamPlayerStatistics.teamId,
                                        userSaisonAllocId: ""
                                    }}/>
                                </TableCell>
                                <TableCell>{V_TeamPlayerStatistics.name}</TableCell>
                                <TableCell sx={{display: {xs: 'none', xl: 'table-cell'}, padding: '0px'}}>
                                {V_TeamPlayerStatistics.teamPlayerId && (
                                    <Box className='teamlogo-fit' sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + V_TeamPlayerStatistics.imageLogoPath + ')'}} />
                                )}
                                </TableCell>
                                <TableCell sx={{display: {xs: 'none', xl: 'table-cell'}}}>{V_TeamPlayerStatistics.userName}</TableCell>
                                <TableCell align="right">{V_TeamPlayerStatistics.sumSpieltage}</TableCell>
                                {props.Type === RankingType.GOALS && (
                                    <TableCell align="right">{V_TeamPlayerStatistics.sumGoals}</TableCell>
                                )}
                                {(props.Type === RankingType.ASSISTS || props.Type === RankingType.SCORER) && (
                                    <TableCell align="right">{V_TeamPlayerStatistics.sumAssists}</TableCell>
                                )}
                                {props.Type === RankingType.GRADE && (
                                    <TableCell align="right">{V_TeamPlayerStatistics.avgGrade.toFixed(2)}</TableCell>
                                )}
                                {props.Type === RankingType.POINTS && (
                                    <TableCell align="right">{V_TeamPlayerStatistics.sumPoints}</TableCell>
                                )}
                                {props.Type === RankingType.SCORER && (
                                    <TableCell align="right">{V_TeamPlayerStatistics.sumGoals}</TableCell>
                                )}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
})

export default TopTeamPlayerRanking