import { Edit, FileDownload } from '@mui/icons-material'
import { Alert, Box, Card, CardContent, CardHeader, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import HttpService from '../../services/HttpService'
import { MatchDayMatch, MatchDayTeam, MatchDay, MatchDayState } from '../../types/MatchDay'
import { AllocRole, LineUp, LineUpItem, Team, TeamPlayer, UserSaisonAlloc } from '../../types/Saison'
import LineUpComponent from './LineUpComponent'
import LineUpItemDialogComponent from './LineUpItemDialogComponent'
import AlertDialog, { AlertType } from '../common/AlertDialog'

function LineUpsComponent(props: {
        lineUps: LineUp[], 
        lineUpItems: LineUpItem[], 
        teamPlayers: TeamPlayer[], 
        teams: Team[], 
        userSaisonAllocs:UserSaisonAlloc[],
        userSaisonAlloc:UserSaisonAlloc,
        matchDayTeams:MatchDayTeam[],
        matchDayMatches:MatchDayMatch[],
        matchDay:MatchDay
        serverDateTime:string
    }) {

    const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [openLineUpItemDialog, setOpenLineUpItemDialog] = React.useState(false);

    const [alertMessage, setAlertMessage] = React.useState("");
    const [selectedUserSaisonAlloc, setSelectedUserSaisonAlloc] = React.useState<UserSaisonAlloc | undefined>(props.userSaisonAlloc);
    const [error, setError] = React.useState("");

    // Kopieren nur erlaubt wenn der Spieltag noch nicht begonnen hat.
    const copyLastLineUpAllowed = props.matchDayMatches.filter(s => s.matchDate < props.serverDateTime).length === 0;

    React.useEffect(function() {

        if(inActivesAvailable()) {
            setError("ACHTUNG: Es sind inaktive Spieler (Wechsel) in deiner Aufstellung. Sollte der Spieler innerhalb der Liga gewechselt sein muss der Spieler nochmal neu aufgestellt werden.")
        }
        else {
            setError("")
        }
        
    }, [props.matchDay.id, reducerValue])

    props.lineUps.sort((a, b) => {
        if(a.userSaisonAllocId === props.userSaisonAlloc.id) return -1;
        return 0;
    })

    props.lineUps.sort((a, b) => {
        if(a !== props.lineUps[0] && b !== props.lineUps[0]) {
            const userSaisonAllocA = props.userSaisonAllocs.find(s => s.id === a.userSaisonAllocId)
            const userSaisonAllocB = props.userSaisonAllocs.find(s => s.id === b.userSaisonAllocId)
            if(userSaisonAllocA !== undefined && userSaisonAllocB !== undefined) {
                if(userSaisonAllocA.playerName.toUpperCase() > userSaisonAllocB.playerName.toUpperCase()) return 1;
                if(userSaisonAllocA.playerName.toUpperCase() < userSaisonAllocB.playerName.toUpperCase()) return -1;
            }
            return 0
        }
        return 0;
    })

    const handleEditLineUpClick = (userSaisonAlloc? : UserSaisonAlloc) => {
        setSelectedUserSaisonAlloc(userSaisonAlloc);
        setOpenLineUpItemDialog(true);
    }

    const handleCloseLineUpDialog = () => {
        setOpenLineUpItemDialog(false);
    } 

    const handleLastLineUpClick = (userSaisonAllocId : string) => {

        HttpService.get<LineUpItem[]>('/MatchDayAPI/LastLineUpItems', {
            params: 
                { 
                    matchDayId: props.lineUps.find(s => s.userSaisonAllocId === userSaisonAllocId)?.matchDayId,
                    userSaisonAllocId: userSaisonAllocId
                }
            }).then(res => {
                if(res.status === 200) {
                    res.data.forEach((lineUpItem) => {
                        let currentLineUpItem = props.lineUpItems.find(s => s.id === lineUpItem.id)
                        if(currentLineUpItem !== undefined) {
                            currentLineUpItem.teamPlayerId = lineUpItem.teamPlayerId
                            currentLineUpItem.substitute = lineUpItem.substitute
                            currentLineUpItem.notPlayed = lineUpItem.notPlayed
                        }
                    })
                }  else if(res.status === 400) {
                    let errorMessage = res.headers["error"];
                    if(errorMessage !== undefined) {
                        setAlertMessage(errorMessage)
                    }    
                }
                forceUpdate();        
            });    
    }

    const inActivesAvailable = () => {
        if(props.matchDay.state === MatchDayState.OPENED) {
            const lineUp = props.lineUps.find(s => s.userSaisonAllocId === props.userSaisonAlloc.id)
            let inActives = 0
    
            if(lineUp) {
                props.lineUpItems.filter(s => s.lineUpId === lineUp.id).forEach((LineUpItem) => {
                    const TeamPlayer = props.teamPlayers.find(s => s.id === LineUpItem.teamPlayerId)
                    if(TeamPlayer) {
                        if(!TeamPlayer.active)
                            inActives++;
                    }
                })
            }
    
            if(inActives > 0) {
                return true
                //setError("ACHTUNG: Es sind inaktive Spieler (Wechsel) in deiner Aufstellung. Sollte der Spieler innerhalb der Liga gewechselt sein muss der Spieler nochmal ausgewöhlt werden.")
            } 
        }
        return false
    }

    return (
        <Box>
            {error.length > 0 && (
                <Alert severity="error" sx={{marginBottom: '10px'}}><Typography fontSize={'medium'}>{error}</Typography></Alert>
            )}
            <Grid container spacing={2}>
                {props.lineUps.map((LineUp) => (
                    <Grid item key={LineUp.id} xs={12} xl={6}>
                        <Card variant="outlined" key={LineUp.userSaisonAllocId} id={'anchor' + LineUp.userSaisonAllocId}>
                            {/* TAG HINZUFÜGEN UM AVATAR ZU SEHEN => avatar={<Avatar sx={{height: '35px', width: '35px'}} alt={props.userSaisonAllocs.find(s => s.id === LineUp.userSaisonAllocId)?.playerName.toUpperCase()} src={`${HttpService.baseUrl + '/images/upload/avatar/' + props.userSaisonAllocs.find(s => s.id === LineUp.userSaisonAllocId)?.avatar}`}/>}} */}
                            <CardHeader titleTypographyProps={{variant: 'h5'}}  title={props.userSaisonAllocs.find(s => s.id === LineUp.userSaisonAllocId)?.playerName.toUpperCase()} action={
                                (LineUp.userSaisonAllocId === props.userSaisonAlloc.id || props.userSaisonAlloc.allocRole === AllocRole.owner) && props.matchDay.state === MatchDayState.OPENED && (
                                    <Box>
                                    {props.matchDay.number > 1 && (
                                        <IconButton 
                                            onClick={() => handleLastLineUpClick(LineUp.userSaisonAllocId)} 
                                            size="small" sx={{ ml: 2 }} 
                                            aria-haspopup="true"
                                            disabled={
                                                props.lineUpItems.filter(lui => lui.lineUpId === props.lineUps
                                                    .find(lu => lu.userSaisonAllocId === LineUp.userSaisonAllocId)?.id)
                                                    .filter(s=>s.teamPlayerId).length === props.lineUpItems.filter(lui => lui.lineUpId === props.lineUps
                                                    .find(lu => lu.userSaisonAllocId === LineUp.userSaisonAllocId)?.id).length || !copyLastLineUpAllowed
                                            }
                                        >
                                            <FileDownload />
                                        </IconButton>
                                    )}  

                                    <IconButton onClick={() => handleEditLineUpClick(props.userSaisonAllocs.find(s => s.id === LineUp.userSaisonAllocId))} size="small" sx={{ ml: 2 }} aria-haspopup="true">
                                        <Edit />
                                    </IconButton>
                                </Box>
                                )
                            }>
                            </CardHeader>
                            <CardContent>
                                <LineUpComponent 
                                    lineUp={props.lineUps.find(lu => lu.userSaisonAllocId === LineUp.userSaisonAllocId)}
                                    lineUpItems={props.lineUpItems.filter(lui => lui.lineUpId === props.lineUps.find(lu => lu.userSaisonAllocId === LineUp.userSaisonAllocId)?.id)}
                                    own={props.userSaisonAlloc.id === LineUp.userSaisonAllocId}
                                    userSaisonAlloc={props.userSaisonAllocs.find(s => s.id === LineUp.userSaisonAllocId)}
                                    teamPlayers={props.teamPlayers.filter(tp => tp.userSaisonAllocId === LineUp.userSaisonAllocId)}
                                    teams={props.teams}
                                    matchDayTeams={props.matchDayTeams}
                                    matchDayMatches={props.matchDayMatches}
                                    serverDateTime={props.serverDateTime}
                                    admin={props.userSaisonAlloc.allocRole === AllocRole.owner}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    ))}
            </Grid>

            <LineUpItemDialogComponent                
                open={openLineUpItemDialog} 
                onClose={handleCloseLineUpDialog} 
                lineUp={props.lineUps.find(lu => lu.userSaisonAllocId === selectedUserSaisonAlloc?.id)} 
                lineUpItems = {props.lineUpItems.filter(lui => lui.lineUpId === props.lineUps.find(lu => lu.userSaisonAllocId === selectedUserSaisonAlloc?.id)?.id)}
                teamPlayers = {props.teamPlayers.filter(tp => tp.userSaisonAllocId === selectedUserSaisonAlloc?.id)}
                matchDayMatches = {props.matchDayMatches}
                matchDayTeams = {props.matchDayTeams}
                teams = {props.teams}
                serverDateTime={props.serverDateTime}
                userSaisonAlloc={ props.userSaisonAlloc }
            />
            <AlertDialog 
                onOk={() => setAlertMessage("")}
                open={alertMessage.length > 0}
                title='FEHLER'
                message={alertMessage}
                type={AlertType.ERROR}
                />
        </Box>
    )
}

export default LineUpsComponent