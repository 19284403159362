import { Avatar, Box, Card, CardContent, CardHeader, Fade, Grid, Grid2, Tab, Typography} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import Loader from "../components/common/Loader";
import LineUpRanking from "../components/saison/LineUpRanking";
import ManagerRanking from "../components/saison/ManagerRanking";
import TippOverview from "../components/tipp/TippOverview";
import HttpService from "../services/HttpService";
import { SaisonModel, TeamPlayer, TeamPlayerPosition, UserSaisonAlloc, V_TeamPlayerStatistics } from "../types/Saison";
import EventsComponent from "../components/saison/EventsComponent";
import TopTeamPlayerRanking, { RankingType } from "../components/saison/TopTeamPlayerRanking";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FieldComponent from "../components/common/FieldComponent";
import CountUp from "react-countup";
import ShoeImage from "../res/images/states/Shoe.png"
import CardBlackImage from "../res/images/states/Card_Black.png"
import ShirtBlackImage from "../res/images/states/Shirt_Black.png"
import GoalBlackImage from "../res/images/states/Goal_Black.png"
import TrophyBlackImage from "../res/images/states/Trophy_Black.png"
import GoalkeeperBlackImage from "../res/images/states/Goalkeeper_Black.png"


export default function SaisonPage() {

    let { id, source } = useParams();
    const [saisonModel, setSaisonModel] = React.useState<SaisonModel>(); 
    const [teamPlayerStatistics, setTeamPlayerStatistics] = React.useState<V_TeamPlayerStatistics[]>();
    const [tabScorerValue, setTabScorerValue] = React.useState("0");
    const [teamOfSaison, setTeamOfSaison] = React.useState<TeamPlayer[]>();

    const navigate = useNavigate();
    const ctx = useContext(AppContext)

    React.useEffect(function() {
        
        window.scrollTo(0, 0);
        
        HttpService.get('/SaisonAPI/Saison', {
            params: { saisonId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }   

                ctx?.setCurrentMatchDay(res.data.currentMatchDay)
                ctx?.setUserSaisonAlloc(res.data.userSaisonAlloc)
                ctx?.setSaison(res.data.saison)
        
                if(res.data.currentMatchDay) {
                    if(ctx?.account?.showActualMatchDay && source === 'home') {
                        navigate('/matchday/' + res.data.currentMatchDay.id)
                    }
                }

                setSaisonModel(res.data);
        });

        HttpService.get('/SaisonAPI/TeamPlayerStatistics', {
            params: { saisonId: id }
            }).then(res => {

                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }  

                const localTeamPlayerOfSaison = new Array<TeamPlayer>();

                if(!res.data)
                    return;

                var localV_TeamPlayerOfSaison:V_TeamPlayerStatistics[] = res.data.teamPlayerStatistics
                    .filter((s:V_TeamPlayerStatistics) => s.position === TeamPlayerPosition.striker && s.sumTeamOfDay > 0)
                    .sort((a:V_TeamPlayerStatistics, b:V_TeamPlayerStatistics) => {
                        return a.sumTeamOfDay < b.sumTeamOfDay ? 1 : a.sumTeamOfDay > b.sumTeamOfDay ? -1 : a.name > b.name ? 1 : -1
                    }
                    ).slice(0, 3)

                localV_TeamPlayerOfSaison = localV_TeamPlayerOfSaison.concat(
                    res.data.teamPlayerStatistics
                        .filter((s:V_TeamPlayerStatistics) => s.position === TeamPlayerPosition.midfielder && s.sumTeamOfDay > 0)
                        .sort((a:V_TeamPlayerStatistics, b:V_TeamPlayerStatistics) => {
                            return a.sumTeamOfDay < b.sumTeamOfDay ? 1 : a.sumTeamOfDay > b.sumTeamOfDay ? -1 : a.name > b.name ? 1 : -1
                        }
                        ).slice(0, 4)
                )

                localV_TeamPlayerOfSaison = localV_TeamPlayerOfSaison.concat(
                    res.data.teamPlayerStatistics
                        .filter((s:V_TeamPlayerStatistics) => s.position === TeamPlayerPosition.defender && s.sumTeamOfDay > 0)
                        .sort((a:V_TeamPlayerStatistics, b:V_TeamPlayerStatistics) => {
                            return a.sumTeamOfDay < b.sumTeamOfDay ? 1 : a.sumTeamOfDay > b.sumTeamOfDay ? -1 : a.name > b.name ? 1 : -1
                        }
                        ).slice(0, 3)
                )

                localV_TeamPlayerOfSaison = localV_TeamPlayerOfSaison.concat(
                    res.data.teamPlayerStatistics
                        .filter((s:V_TeamPlayerStatistics) => s.position === TeamPlayerPosition.goalkeeper && s.sumTeamOfDay > 0)
                        .sort((a:V_TeamPlayerStatistics, b:V_TeamPlayerStatistics) => {
                            return a.sumTeamOfDay < b.sumTeamOfDay ? 1 : a.sumTeamOfDay > b.sumTeamOfDay ? -1 : a.name > b.name ? 1 : -1
                        }
                        ).slice(0, 1)
                )

                localV_TeamPlayerOfSaison.forEach((V_TeamPlayer:V_TeamPlayerStatistics, index) => {
                    localTeamPlayerOfSaison.push({
                        active: true,
                        id: V_TeamPlayer.teamPlayerId,
                        info: "SUM_TEAM_OF_DAY:" + V_TeamPlayer.sumTeamOfDay,
                        kickerPrice: V_TeamPlayer.kickerPrice,
                        name: V_TeamPlayer.name,
                        newTeamPlayerId: "",
                        note: "",
                        noteDate: "",
                        number: V_TeamPlayer.number,
                        position: V_TeamPlayer.position,
                        price: V_TeamPlayer.price,
                        teamId: V_TeamPlayer.teamId,
                        userSaisonAllocId: ""
                    })
                })

                setTeamOfSaison(localTeamPlayerOfSaison)
                setTeamPlayerStatistics(res.data.teamPlayerStatistics)
        })   

    }, [id])

    function handleLastMatchDayClick() {
        navigate('/matchday/' + saisonModel?.lastMatchDay?.id)
    }

    const userSaisonAllocs:UserSaisonAlloc[] = []
    saisonModel?.managers?.forEach(function(manager) {
        userSaisonAllocs.push(manager.userSaisonAlloc)
    })

    return (
        <div>
            {saisonModel && (
                <Fade in>
                    <div>
                        {/* <a href="https://www.paypal.com/donate/?hosted_button_id=KWJ84A5PH8WT6" target="_blank">Spenden...</a> */}
                        <Grid container spacing={2} sx={{marginBottom: 2}}>
                            <Grid item xs={12} xl={saisonModel.lastMatchDay ? 5 : 12}>
                                <Box>
                                    <Card variant="outlined">
                                        <CardHeader title={saisonModel.saison.name?.toUpperCase()} />
                                        <CardContent>
                                            <ManagerRanking 
                                                Managers={saisonModel.managers} 
                                                UserSaisonAlloc={saisonModel.userSaisonAlloc} 
                                                SaisonState={saisonModel.saison.state}
                                                SaisonRanking={true}
                                            />
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>

                            {saisonModel.lastMatchDay && (
                                <Grid item xs={12} xl={7}>
                                        <Box>
                                            <Card variant="outlined" onClick={handleLastMatchDayClick} sx={{cursor: 'pointer'}}>
                                                <CardHeader title={saisonModel.lastMatchDay.number.toString() + ". SPIELTAG"} />
                                                <CardContent>
                                                    <LineUpRanking LineUps={saisonModel.lastLineUps} UserSaisonAllocs={userSaisonAllocs} UserSaisonAlloc={saisonModel.userSaisonAlloc} />
                                                </CardContent>
                                            </Card>
                                        </Box>
                                </Grid>
                            )}
                        </Grid>


                        {/* <Box sx={{marginBottom: 2}}>
                            <Card variant="outlined">
                                <CardHeader title={saisonModel.saison.name?.toUpperCase()} />
                                <CardContent>
                                    <ManagerRanking 
                                        Managers={saisonModel.managers} 
                                        UserSaisonAlloc={saisonModel.userSaisonAlloc} 
                                        SaisonState={saisonModel.saison.state}
                                        SaisonRanking={true}
                                    />
                                </CardContent>
                            </Card>
                        </Box> */}

                        {/* {saisonModel.lastMatchDay && (
                            <Box sx={{marginBottom: 2}}>
                                <Card variant="outlined" onClick={handleLastMatchDayClick} sx={{cursor: 'pointer'}}>
                                    <CardHeader title={"SPIELTAG ".concat(saisonModel.lastMatchDay.number.toString())} />
                                    <CardContent>
                                        <LineUpRanking LineUps={saisonModel.lastLineUps} UserSaisonAllocs={userSaisonAllocs} UserSaisonAlloc={saisonModel.userSaisonAlloc} />
                                    </CardContent>
                                </Card>
                            </Box>
                        )} */}

                        {saisonModel.saison.tippspiel && saisonModel.lastMatchDay && (
                            <Box sx={{marginBottom: 2}}>
                                <Card variant="outlined">
                                    <CardHeader title={'TIPPSPIEL'} />
                                    <CardContent>
                                        <TippOverview 
                                            UserSaisonAllocs={saisonModel.userSaisonAllocs} 
                                            UserSaisonAlloc={saisonModel.userSaisonAlloc} 
                                            MatchDayMatches={saisonModel.lastMatchDayMatches}
                                            MatchDayTeams={saisonModel.lastMatchDayTeams}
                                            Teams={saisonModel.teams}
                                            Tippers={saisonModel.tippers}
                                            Tipps={saisonModel.tipps}
                                            ServerDateTime={saisonModel.serverDateTime}
                                            MatchDay={saisonModel.lastMatchDay}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        )}

                        {teamPlayerStatistics && saisonModel.lastMatchDay && (
                        <Grid2 container spacing={2} sx={{marginBottom: 2}} overflow={'hidden'}>

                            <Grid2 size={{xs: 12, md: 4}}>
                                <Card sx={{height: '100%'}}>
                                    <CardHeader title="ZAHLEN" />
                                    <CardContent>
                                        <Grid2 container spacing={2}>
                                            <Grid2 size={{xs: 6, md: 4}}>
                                                <State 
                                                    bgcolor="#8FBC8F"
                                                    image={GoalBlackImage}
                                                    number={teamPlayerStatistics.reduce((sum, current) => sum + current.sumGoals, 0)}
                                                    unit="TORE"
                                                    durationInSeconds={3}
                                                />
                                            </Grid2>
                                            <Grid2 size={{xs: 6, md: 4}}>
                                                <State 
                                                    bgcolor="#8FBC8F"
                                                    image={ShoeImage}
                                                    number={teamPlayerStatistics.reduce((sum, current) => sum + current.sumAssists, 0)}
                                                    unit="VORARBEITEN"
                                                    durationInSeconds={2.5}
                                                />
                                            </Grid2>
                                            <Grid2 size={{xs: 6, md: 4}} sx={{marginBottom: {md: 4}}}>
                                                <State 
                                                    bgcolor="#8FBC8F"
                                                    image={GoalkeeperBlackImage}
                                                    number={teamPlayerStatistics.reduce((sum, current) => sum + current.sumPoints, 0)}
                                                    unit="SPIELERPUNKTE"
                                                    durationInSeconds={2}
                                                />
                                            </Grid2>
                                            <Grid2 size={{xs: 6, md: 4}}>
                                                <State 
                                                    bgcolor="#8FBC8F"
                                                    image={CardBlackImage}
                                                    number={teamPlayerStatistics.reduce((sum, current) => sum + current.sumRedCards, 0) + teamPlayerStatistics.reduce((sum, current) => sum + current.sumYellowRedCards, 0)}
                                                    unit="KARTEN"
                                                    durationInSeconds={2}
                                                />
                                            </Grid2>
                                            <Grid2 size={{xs: 6, md: 4}}>
                                                <State 
                                                    bgcolor="#8FBC8F"
                                                    image={ShirtBlackImage}
                                                    number={teamPlayerStatistics.reduce((sum, current) => sum + current.sumTeamOfDay, 0)}
                                                    unit="TEAM DES TAGES"
                                                    durationInSeconds={2}
                                                />
                                            </Grid2>
                                            <Grid2 size={{xs: 6, md: 4}}>
                                                <State 
                                                    bgcolor="#8FBC8F"
                                                    image={TrophyBlackImage}
                                                    number={teamPlayerStatistics.reduce((sum, current) => sum + current.sumPlayerOfDay, 0)}
                                                    unit="SPIELER DES TAGES"
                                                    durationInSeconds={3}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>

                            <Grid2 size={{xs: 12, md: 4}}>
                                <Card>
                                    <CardContent>
                                        <TabContext value={tabScorerValue}>                            
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                                                <TabList /*variant="scrollable"*/ /*centered*/ onChange={(event: React.SyntheticEvent, newValue: string) => setTabScorerValue(newValue)} aria-label="lab API tabs example">
                                                    <Tab label="PUNKTE" value="0" />
                                                    {/* <Tab label="TORE" value="1" />
                                                    <Tab label="ASSISTS" value="2" /> */}
                                                    <Tab label="SCORER" value="3" />
                                                    <Tab label="NOTEN" value="4" />
                                                </TabList>
                                            </Box>
                                            <TabPanel value="0" sx={{padding: 0.5}}>
                                                <TopTeamPlayerRanking
                                                        TeamPlayerStatistics={teamPlayerStatistics}
                                                        Type={RankingType.POINTS}
                                                        Cnt={9}
                                                    />
                                            </TabPanel>
                                            {/* <TabPanel value="1" sx={{padding: 0.5}}>
                                                <TopTeamPlayerRanking
                                                        TeamPlayerStatistics={teamPlayerStatistics}
                                                        Type={RankingType.GOALS}
                                                        Cnt={9}
                                                    />
                                            </TabPanel>
                                            <TabPanel value="2" sx={{padding: 0.5}}>
                                                <TopTeamPlayerRanking
                                                        TeamPlayerStatistics={teamPlayerStatistics}
                                                        Type={RankingType.ASSISTS}
                                                        Cnt={9}
                                                    />
                                            </TabPanel> */}
                                            <TabPanel value="3" sx={{padding: 0.5}}>
                                                <TopTeamPlayerRanking
                                                        TeamPlayerStatistics={teamPlayerStatistics}
                                                        Type={RankingType.SCORER}
                                                        Cnt={9}
                                                    />
                                            </TabPanel>
                                            <TabPanel value="4" sx={{padding: 0.5}}>
                                                <TopTeamPlayerRanking
                                                        TeamPlayerStatistics={teamPlayerStatistics}
                                                        Type={RankingType.GRADE}
                                                        Cnt={9}
                                                    />
                                            </TabPanel>
                                        </TabContext>
                                    </CardContent>
                                </Card>
                            </Grid2>

                            <Grid2 size={{xs: 12, md: 4}}>
                                <Card>
                                    <CardHeader title="TEAM DER SAISON"/>
                                    <CardContent sx={{paddingBottom:1, height: '420px'}}>
                                        <FieldComponent teamPlayers={teamOfSaison ? teamOfSaison : []} height="100%" />
                                        {/* <FieldComponent teamPlayers={[]} height="100%" /> */}
                                    </CardContent>
                                </Card>
                            </Grid2>

                        </Grid2>
                        )}

                        <Box sx={{marginBottom: 2}}>
                            <Card variant="outlined">
                                <CardHeader title="TERMINE"/>
                                <CardContent>
                                    <EventsComponent SaisonId={saisonModel.saison.id} />
                                </CardContent>
                            </Card>
                        </Box>

                        {/* {saisonModel.currentMatchDay && (
                            <Box >
                                <Card variant="outlined">
                                    <CardHeader title={"SPIELTAG ".concat(saisonModel.currentMatchDay.number.toString())} onClick={() => navigate('/matchday/' + saisonModel?.currentMatchDay?.id)} sx={{cursor: 'pointer'}} />
                                    <CardContent onClick={() => navigate('/matchday/' + saisonModel?.currentMatchDay?.id)} sx={{cursor: 'pointer'}}>
                                        <MatchDayMatchesComponent 
                                            matchDayMatches={saisonModel.currentMatchDayMatches}
                                            matchDayTeams={saisonModel.currentMatchDayTeams}
                                            teams={saisonModel.teams}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        )} */}
                    </div>
                </Fade>
            )}
            {!saisonModel && (
                <Loader delay={1500}>Saison wird geladen...</Loader>
            )}
        </div>
    )
}

const State = (props: {number:number, image:string, bgcolor:string, unit:string, durationInSeconds?:number}) => {
    return (
        <Box textAlign={'center'} borderBottom={'1px solid'}>
            <Avatar sx={{ bgcolor: "transparent", width: "72px", height: '72px', margin: 'auto' }} variant="rounded">
                <img alt="avatar" src={props.image} style={{objectFit: 'contain', height: '100%', width: '100%', padding: 5, opacity: 0.7}}></img>
            </Avatar>
            <Typography variant="h4" textAlign={'center'} sx={{fontWeight: 'bold'}}>
                <CountUp end={props.number} enableScrollSpy={true} scrollSpyOnce={true} scrollSpyDelay={400} duration={props.durationInSeconds}/>
            </Typography>
            <Typography fontSize={'0.8rem'} textAlign={'center'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
                {props.unit}
            </Typography>
        </Box>
    )
}