import { Avatar, Chip, Grid2, Box, Typography, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { MatchDayMatchStatistic, Team } from "../../types/Saison";
import HttpService from "../../services/HttpService";
import { useNavigate } from "react-router-dom";

export default function LastMatchesComponent(props: {MatchDayMatchStatistics:MatchDayMatchStatistic[]}) {

    const navigate = useNavigate();

    const MatchTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
            ))({
            [`& .${tooltipClasses.tooltip}`]: {
                width: 280
            },
    });

    console.log(props.MatchDayMatchStatistics)

    return (
        <Box>
            <Grid2 container>
                <Grid2 size={4} textAlign={'right'}>
                    <Chip
                        avatar={<Avatar sx={{backgroundColor: 'whitesmoke'}}>{props.MatchDayMatchStatistics.filter(s => s.won).length}</Avatar>}
                        label='S'
                        color="success"
                    />
                </Grid2>
                <Grid2 size={4} textAlign={'center'}>
                    <Chip sx={{backgroundColor:'#cccccc'}}
                        avatar={<Avatar sx={{backgroundColor: 'whitesmoke'}}>{props.MatchDayMatchStatistics.filter(s => s.draw).length}</Avatar>}
                        label="U"
                    />
                </Grid2>
                <Grid2 size={4} textAlign={'left'}>
                    <Chip
                        avatar={<Avatar sx={{backgroundColor: 'whitesmoke'}}>{props.MatchDayMatchStatistics.filter(s => s.lost).length}</Avatar>}
                        label="N"
                        color="error"
                    />
                </Grid2>
            </Grid2>
            <Grid2 container sx={{marginTop: '2rem'}}>
                {props.MatchDayMatchStatistics.map((MatchDayMatchStatistic) => (
                    <MatchTooltip key={MatchDayMatchStatistic.matchDayMatch.id} title={
                        <Grid2 container>
                            <Grid2 size={2}>
                                <Box className='teamlogo-fit' height={'40px'} sx={{marginTop:'0.5rem', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + MatchDayMatchStatistic.team1.team.imageLogoPath  + ')'}}>&nbsp;
                                </Box>
                            </Grid2>
                            <Grid2 size={2} textAlign={'right'} sx={{alignSelf:'center'}}>
                                <Typography variant="h6">{MatchDayMatchStatistic.team1.team.shortName}</Typography>
                            </Grid2>
                            <Grid2 size={4} textAlign={'center'} sx={{alignSelf:'center'}}>
                                <Typography variant="h5">{MatchDayMatchStatistic.team2.matchDayTeam.goalsConceded} - {MatchDayMatchStatistic.team1.matchDayTeam.goalsConceded}</Typography>
                            </Grid2>
                            <Grid2 size={2} textAlign={'left'} sx={{alignSelf:'center'}}>
                                <Typography variant="h6">{MatchDayMatchStatistic.team2.team.shortName}</Typography>
                            </Grid2>
                            <Grid2 size={2}>
                                <Box className='teamlogo-fit' height={'40px'} sx={{marginTop:'0.5rem', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + MatchDayMatchStatistic.team2.team.imageLogoPath  + ')'}}>&nbsp;
                                </Box>
                            </Grid2>
                        </Grid2>
                    }>  
                    <Grid2 size={'grow'} sx={{paddingLeft: '5px', cursor: 'pointer'}} onClick={() => navigate('/match/' + MatchDayMatchStatistic.matchDayMatch.id)}>
                            {MatchDayMatchStatistic.won && (
                                <Chip color="success" sx={{width:'100%', height:'1.2rem', cursor: 'pointer'}} />
                            )}
                            {MatchDayMatchStatistic.lost && (
                                <Chip color="error" sx={{width:'100%', height:'1.2rem', cursor: 'pointer'}} />
                            )}
                            {MatchDayMatchStatistic.draw && (
                                <Chip sx={{width:'100%', height:'1.2rem', cursor: 'pointer'}} />
                            )}
                            <Box className='teamlogo-fit' height={'40px'} sx={{marginTop:'0.5rem', backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + 
                                (MatchDayMatchStatistic.team1.team.id !== MatchDayMatchStatistic.teamId ? MatchDayMatchStatistic.team1.team.imageLogoPath : MatchDayMatchStatistic.team2.team.imageLogoPath) +
                                ')'}}>&nbsp;
                            </Box>
                        </Grid2>
                    </MatchTooltip>
                ))}
            </Grid2>
        </Box>
    )
}




