import styled from "@emotion/styled";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Rating, Select, Skeleton, TextField, Typography } from "@mui/material";
import { LineUpItem, TeamPlayerPosition, TeamPlayerStatisticsModel, UserSaisonAlloc } from "../../types/Saison";
import HttpService from "../../services/HttpService";
import React from "react";
import { Fade } from "react-awesome-reveal";

function TeamPlayerStatisticsDialogComponent(props: {
    open:boolean,
    onClose: () => void;
    teamPlayerId?:string,
    lineUpItem?:LineUpItem,
    admin:boolean
}) {

    const { open, onClose } = props;
    const [ model, setModel ] = React.useState<TeamPlayerStatisticsModel>()
    const [ grade, setGrade ] = React.useState<number>(0)

    React.useEffect(function() {
        setModel(undefined);
        setGrade(0);
        HttpService.get('/SaisonAPI/TeamPlayerDetails', {
            params: {
                teamPlayerId: props.teamPlayerId,
                lineUpItemId: props.lineUpItem?.id        
            }
        }
        ).then(res => {
            if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log(res.data);
            }       
            setModel(res.data);
            if(res.data.lineUpItem) {
                setGrade(res.data.lineUpItem.grade)
            }   
        });       
    }, [props.teamPlayerId, props.lineUpItem])

    const handleClose = () => {
        onClose();
    }

    const handleSave = () => {
        if(model)
            model.lineUpItem.grade = grade

        HttpService.post('/MatchDayAPI/LineUpItem', model?.lineUpItem
        ).then(res => {
            if(res.status === 200) {
                handleClose();
            }
        })
    }

    return (
        <TeamPlayerDialog open={open} fullWidth={true}>
            <DialogTitle>
                <Grid container
                    direction="row"
                    sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    }}
                >
                    {/* <Grid item>
                        <ShirtComponent 
                            lineUpItem={props.lineUpItem}
                            number={model?.teamPlayerStatistics.number}
                            teamPlayerPosition={model?.teamPlayerStatistics.position}
                            showPosition={true}
                        />
                    </Grid> */}
                     <Grid item>
                        <Box lineHeight={'normal'}>{model?.teamPlayerStatistics.name} {model?.teamPlayerStatistics.number ? "(" + model?.teamPlayerStatistics.number + ")" : ""}</Box>
                        <Rating
                            value={6 - (model?.teamPlayerStatistics && model?.teamPlayerStatistics.avgGrade ? model?.teamPlayerStatistics.avgGrade : 6)}
                            readOnly={true}
                            precision={0.1}

                        />
                    </Grid>
                    <Grid item xs={true}>
                        {props.teamPlayerId && (
                                <Box className='teamlogo-fit' height={48} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + model?.teamPlayerStatistics.imageLogoPath + ')', backgroundPositionX: 'right'}} />
                        )}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                {!model && (
                    <Fade duration={500}>
                        <Skeleton variant="rounded" width={'20%'} sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" width={'30%'} sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                        {props.lineUpItem && (
                            <Box>
                                <Skeleton variant="rounded" width={'15%'} sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                                <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                                <Skeleton variant="rounded" sx={{fontSize: "2rem", marginBottom: "9px"}} animation="wave"/>
                            </Box>
                        )}
                    </Fade>
                )}
                {model && !model.teamPlayerStatistics && (
                    <Box width={'100%'} textAlign={'center'}>KEINE STATISTIKEN VORHANDEN</Box>
                )} 
                {model && model.teamPlayerStatistics && (
                    <Box>
                        <Typography variant="subtitle1" marginBottom={'10px'}>ALLGEMEIN</Typography>
                        <TextField sx={{paddingBottom: "10px" }}
                            label="POSITION"
                            value={
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.goalkeeper ? 'TOR' :
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.defender ? 'ABWEHR' :
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.midfielder ? 'MITTELFELD' :
                                model?.teamPlayerStatistics.position === TeamPlayerPosition.striker ? 'STURM' : ''
                            }
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="PREIS"
                            value={model?.teamPlayerStatistics.price}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="PREIS (KICKER)"
                            value={model?.teamPlayerStatistics.kickerPrice}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="MANAGER"
                            value={model?.teamPlayerStatistics.userName}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <Typography variant="subtitle1" marginBottom={'10px'}>STATISTIKEN</Typography>
                        <TextField sx={{paddingBottom: "10px" }}
                            label="EINSÄTZE"
                            value={model?.teamPlayerStatistics.sumSpieltage}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="NOTE DURCHSCHNITT"
                            value={model?.teamPlayerStatistics.avgGrade.toFixed(2)}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="TORE"
                            value={model?.teamPlayerStatistics.sumGoals}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="VORARBEITEN"
                            value={model?.teamPlayerStatistics.sumAssists}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                    </Box>
                )} 
                {model && model.lineUpItem && (
                    <Box>
                        <Typography variant="subtitle1" marginBottom={'10px'}>SPIELTAG</Typography>
                        <TextField sx={{paddingBottom: "10px" }}
                            label="TORE"
                            value={model?.lineUpItem.goals}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        <TextField sx={{paddingBottom: "10px" }}
                            label="VORARBEITEN"
                            value={model?.lineUpItem.assists}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            size="small"
                        />
                        {props.admin && (
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">NOTE</InputLabel>
                                <Select size="small" label="NOTE" onChange={e => setGrade(Number(e.target.value))} value={grade}>
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={1.5}>1.5</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={2.5}>2.5</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={3.5}>3.5</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={4.5}>4.5</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={5.5}>5.5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                </Select>
                                {/* <FormHelperText>NOTE</FormHelperText> */}
                            </FormControl>
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>SCHLIESSEN</Button>
                <Button onClick={handleSave} disabled={grade === 0} sx={{display: props.admin ? 'inline-flex' : 'none'}}>ÜBERNEHMEN</Button>
            </DialogActions>
        </TeamPlayerDialog>
    )
}

export default TeamPlayerStatisticsDialogComponent

const TeamPlayerDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        margin: 0,
        maxHeight: 'calc(100% - 10px)',
        width: 'calc(100% - 10px)',
    }
}))